import { HTMLController } from "./../../classes/mvc/HTML/HTMLController";
import { Globals } from "./../../classes/Globals";
import { ModelSearch } from "./ModelSearch";
import { Strings } from "./../../libs/Strings";
import { modules } from "../../main";
import { Json } from "../../libs/Json";
import { OrderByItem } from "./OrderByItem";
import { Filter } from "./Filters/Filter";
import { PriceFilter } from "./Filters/PriceFilter";
import { BrandFilter } from "./Filters/BrandFilter";
import jQuery = require( "jquery" );

import { Icons, findIcon } from "../../libs/Icons";

import "jquery-ui";
import { Pair } from "../../libs/Pair";
import { Collapse } from "../../classes/Collapse";
import { ClickEvent } from "../../classes/ClickEvent";

declare var ezentrum_variables:any;

export class ControllerSearch extends HTMLController<ModelSearch>{		
	private static CASPARDO_QUERY:string;
	private static CASPARDO_BRAND:string;

	private static SEARCH_URL:string;
	private static TARGET_URL_QUERY:string;

	private static ARTICLE_SYNC:boolean;
	private static AUTO_SCROLL:boolean;
	private static AUTO_SCROLL_FIRST_CLICK:boolean;

	private static PAGE_TYPE:number;
	private static SCROLL_DISTANCE_TO_BOTTOM:number;

	private static TAB_INFORMATION_DISPLAY_KEYWORDS:boolean;
	private static TAB_INFORMATION_DISPLAY_DESCRIPTION:boolean;

	private static TAB_SHOP_DUMMY_ROW_COUNT:number;
	private static TAB_SHOP_DUMMY_ROW:JQuery<HTMLElement>;
	
	private static ODER_BY_ITEMS:Array<OrderByItem>;
	private static FILTERS:Array<Filter>;

	private static ARTICLE_SYNC_ITEMS:Array<Pair<string, string>>;
	private static DISABLED_FILTERS:Array<string>;
	
	private query:string;

	private type:string;
	private tab:string;

	private page:number;
	private pages:number;

	private hits:number;

	private tabsBuilded:boolean;
	private assignedEvents:boolean;
	private filtersLoaded:boolean;
	private activatedAutoScrolling:boolean;

	private currentOrderByItem:number;

	/**
	 * 
	 * HTML Elements
	 */
	private tabs:JQuery<HTMLElement>;

	private filtersBox:JQuery<HTMLElement>;
	private orderbyBox:JQuery<HTMLElement>;
	private recommendationsBox:JQuery<HTMLElement>;

	private nextPageButton:JQuery<HTMLElement>;

	private tabShopDummyRows:Array<JQuery<HTMLElement>>;

	public constructor ( accessName:string, accessID:number, element:JQuery<HTMLElement> ){
		super( new ModelSearch(), accessName, accessID, element );
	}

	public initGlobals ():void{
		var target_url = this.getModule().getConfig( "target_url" ) + "&sKontaktID="+ modules.getKontaktID() +"&sKontaktKEY="+ modules.getKontaktKey() +"&sTICKCOUNT="+ modules.getTickcount();

		ControllerSearch.CASPARDO_QUERY = ezentrum_variables.T_caspardo_query;
		ControllerSearch.CASPARDO_BRAND = ezentrum_variables.T_caspardo_brand;

		if ( typeof ControllerSearch.CASPARDO_QUERY !== "undefined" ) {
			ControllerSearch.CASPARDO_QUERY = Strings.stripSpecialChars( ControllerSearch.CASPARDO_QUERY );
		}
		if ( typeof ControllerSearch.CASPARDO_BRAND !== "undefined" ) {
			ControllerSearch.CASPARDO_BRAND = Strings.stripSpecialChars( ControllerSearch.CASPARDO_BRAND );
		}

		ControllerSearch.SEARCH_URL = this.getModule().getConfig( "search_url" );
		ControllerSearch.TARGET_URL_QUERY = target_url + "&query=";

		ControllerSearch.ARTICLE_SYNC = this.getModule().getConfig( "article_sync" ) || false;
		ControllerSearch.PAGE_TYPE = this.getModule().getConfig( "tabs.shop.display_article_per_page_type" ) || 1;

		ControllerSearch.AUTO_SCROLL = this.getModule().getConfig( "autoscroll" ) == true;
		ControllerSearch.SCROLL_DISTANCE_TO_BOTTOM = parseInt( this.getModule().getConfig("scroll_distance_to_bottom") );

		ControllerSearch.TAB_INFORMATION_DISPLAY_DESCRIPTION = this.getModule().getConfig( "tabs.information.display_description" ) == true;
		ControllerSearch.TAB_INFORMATION_DISPLAY_KEYWORDS = this.getModule().getConfig( "tabs.information.display_keywords" ) == true;

		ControllerSearch.TAB_SHOP_DUMMY_ROW_COUNT = 0;

		ControllerSearch.ODER_BY_ITEMS = new Array();
		ControllerSearch.ODER_BY_ITEMS.push( new OrderByItem( 1, this.getModule().getLabel( "orderby_option_relevance" ) ) );
		ControllerSearch.ODER_BY_ITEMS.push( new OrderByItem( 2, this.getModule().getLabel( "orderby_option_price_ascending" ) ) );
		ControllerSearch.ODER_BY_ITEMS.push( new OrderByItem( 3, this.getModule().getLabel( "orderby_option_price_descending" ) ) );

		ControllerSearch.FILTERS = new Array();
		ControllerSearch.FILTERS.push( new PriceFilter( this ) );
		ControllerSearch.FILTERS.push( new BrandFilter( this ) );

		ControllerSearch.ARTICLE_SYNC_ITEMS = new Array();
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair( "price", "price" ));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair( "info", "info3" ));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair( "unit", "info1" ));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair( "amount", "bestmenge" ));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair( "availability", "availability" ));

		ControllerSearch.DISABLED_FILTERS = new Array();

		var disabledFilters:Array<string> = this.getModule().getConfig( "disabled_filters" );
		if ( disabledFilters != null && disabledFilters.length ){
			ControllerSearch.DISABLED_FILTERS = disabledFilters;
		}

		var tabShopDummyRowCount:number = Number( this.getModule().getConfig( "tabs.shop.dummy_row_count" ) );
		if ( !isNaN( tabShopDummyRowCount ) ){
			ControllerSearch.TAB_SHOP_DUMMY_ROW_COUNT = tabShopDummyRowCount;
		}

		var dummyRow = jQuery( this.getModule().getComponent( "tabs.shop.dummy_row", false ) );
		if ( dummyRow.length ){
			ControllerSearch.TAB_SHOP_DUMMY_ROW = dummyRow;
		}
    }

	public run ():void{
		if ( typeof ControllerSearch.CASPARDO_QUERY !== "undefined" && typeof ControllerSearch.CASPARDO_BRAND !== "undefined" ) {
			this.query = ControllerSearch.CASPARDO_QUERY;

			this.type = "shop";
			this.tab = "shop";
			
			this.page = 1;
			this.pages = 1;
			
			this.hits = null;

			this.tabsBuilded = false;
			this.assignedEvents = false;
			this.filtersLoaded = true;
			this.activatedAutoScrolling = false;

			this.currentOrderByItem = 1;

			this.tabShopDummyRows = new Array();
			if ( ControllerSearch.TAB_SHOP_DUMMY_ROW_COUNT > 0 ){
				for (let index = 0; index < ControllerSearch.TAB_SHOP_DUMMY_ROW_COUNT; index++) {
					this.tabShopDummyRows.push( ControllerSearch.TAB_SHOP_DUMMY_ROW.clone() );
				}
			}

			this.tabs =  this.getElement();
			
			this.getModule().addView( "search_url", ControllerSearch.SEARCH_URL );
			ControllerSearch.SEARCH_URL = this.processOne( "search_url", "id", modules.getGlobalConfig( "caspardo_language_mapping." + modules.getLanguageCode() ) );

			this.loadAllViews();

			/**
			*
			* Start the search
			*/
			if ( this.tabs.length ) {
				this.refreshAll();
			}
		} else {
			this.getModule().error( Globals.MODULE_LOADING_ERROR + " folgende Variablen nicht definiert wurden: ezentrum_variables.T_caspardo_query und ezentrum_variables.T_caspardo_brand" );
		}
	}

	/**
	 * 
	 * Load all handlebars view
	 */
	private loadAllViews ():void{
		/**
		 * 
		 * Recommendations
		 */
		this.getModule().addView("recommendations_row_container", this.getModule().getComponent( "recommendations.row_container", false ) );
		this.getModule().addView("recommendations_row_content_picture", this.getModule().getComponent( "recommendations.row_content_picture", false ) );
		this.getModule().addView("recommendations_row_content_text", this.getModule().getComponent( "recommendations.row_content_text", false ) );
		
		/**
		* 
		* Orderby
		*/
		this.getModule().addView("orderby_container", this.getModule().getComponent( "orderby.container", false ) );
		this.getModule().addView("orderby_row", this.getModule().getComponent( "orderby.row", false ) );

		/**
		* 
		* Orderby
		*/
		this.getModule().addView("doyoumean_container", this.getModule().getComponent( "doyoumean.container", false ) );

		/**
		* 
		* Filters
		*/
		this.getModule().addView("filter_container", this.getModule().getComponent( "filter.container", false ) );
			
		/**
		* 
		* Tab
		* Shop
		*/
		this.getModule().addView("tab_shop_structure", this.getModule().getComponent( "tabs.shop.structure", false ) );
		this.getModule().addView("tab_shop_row", this.getModule().getComponent( "tabs.shop.row", false ) );
	
		/**
		* 
		* Tab
		* Information
		*/
		this.getModule().addView("tab_information_structure", this.getModule().getComponent( "tabs.information.structure", false ) );
		
		this.getModule().addView("tab_information_content_structure", this.getModule().getComponent( "tabs.information.content.structure", false ) );
		this.getModule().addView("tab_information_content_container_description", this.getModule().getComponent( "tabs.information.content.container_description", false ) );
		this.getModule().addView("tab_information_content_container_keywords", this.getModule().getComponent( "tabs.information.content.container_keywords", false ) );
		this.getModule().addView("tab_information_content_container_content", this.getModule().getComponent( "tabs.information.content.container_content", false ) );
		this.getModule().addView("tab_information_content_row_keywords", this.getModule().getComponent( "tabs.information.content.row_keywords", false ) );
	}

	/**
	*
	* Build the tabs if necessary
	* Process the content
	* Display the content
	*/
	public refreshAll ():void{
		this.page = 1;

		var search_result = this.getModel().getSearchResults( ControllerSearch.SEARCH_URL, this.tab, this.type, this.currentOrderByItem, ControllerSearch.PAGE_TYPE, this.filtersLoaded, this.buildFiltersURL(), this.page, this.query );
		if ( search_result != null ) {

			var tabs = this.getModel().getTabs( search_result );
			var results = this.getModel().getResults( this.tab, search_result );
			var filters = this.getModel().getFilters( this.tab, search_result );
			var recommendations = this.getModel().getRecommendations( search_result );
			var doyoumean = this.getModel().getDoyoumean( this.tab, search_result );

			this.hits = this.getModel().getCurrentTabHits( this.tab, search_result );
			this.page = this.getModel().getPage( this.tab, search_result );
			this.pages = this.getModel().getAmountPages( this.tab, search_result );

			/**
			*
			* Build the tabs if necessary
			*/
			if ( !this.tabsBuilded ) {
				this.buildTabs( tabs );
			}
			
			/**
			*
			* Build current tab html structure
			*/
			this.buildCurrentTabStructure();

			/**
			*
			* Check the results
			*/
			if ( results != null ) {
				
				/**
				*
				* Build or destroy the recommendations
				*/
				if ( recommendations != null ) {
					this.buildCurrentRecommendations( recommendations );
				} else {
					this.destroyRecommendations();
				}

				/**
				*
				* Display the order by
				*/
				this.buildOrderby();
				
				/**
				*
				* Process and display the filters
				*/
				if ( this.filtersLoaded ) {
					this.buildCurrentTabFilters( filters );
				}

			} else {
				this.destroyRecommendations();
			}

			if ( results != null || doyoumean != null ) {
				var current_tab_content_element = jQuery( "#tab-" + this.tab + " .item_listing" );
				if ( current_tab_content_element.length ) {
					/**
					*
					* Clear current result content
					*/
					current_tab_content_element.html( "" );

					/**
					*
					* Process and display the results
					*/
					this.refreshCurrentTabContent( results, doyoumean );
				}
			}
			
			/**
			*
			* Set tabs hits
			*/
			this.setTabsHits( tabs );
			this.setCurrentTabHits();

			/**
			*
			* Destroy the next page button if the current page is also the last page
			*/
			this.buildCurrentTabAutoReload();

			/**
			*
			* Bind all static events
			*/
			if ( !this.assignedEvents ) {
				this.assignStaticEvents();
				
				for (let i = 0; i < ControllerSearch.FILTERS.length; i++) {
					if (ControllerSearch.FILTERS[i].isActive()){
						ControllerSearch.FILTERS[i].execute();
					}	
				}
			}
		}
	}

	/**
	 * 
	 * Build the URL part with all filters
	 */
	private buildFiltersURL ():string{
		var filtersURL = "";

		for (let i = 0; i < ControllerSearch.FILTERS.length; i++) {
			if (ControllerSearch.FILTERS[i].isActive()){
				filtersURL += ControllerSearch.FILTERS[i].buildURL();
			}
		}

		return filtersURL;
	}

	/**
	*
	* Refresh only the content area
	* Used to display the next page
	*/
	private refresh_content ():void{
		var search_result = this.getModel().getSearchResults( ControllerSearch.SEARCH_URL, this.tab, this.type, ControllerSearch.ODER_BY_ITEMS[this.currentOrderByItem].getID(), ControllerSearch.PAGE_TYPE, !this.filtersLoaded, this.buildFiltersURL(), this.page, this.query );
		if ( search_result != null ) {
			var results = this.getModel().getResults( this.tab, search_result );

			// TODO: reInit des number input moduls

			this.refreshCurrentTabContent( results, null );
			this.buildCurrentTabAutoReload();
			this.onContentChange();
		}
	}

	/**
	*
	* Build the tab structure
	*/
	private buildTabs ( tabs:Array<Object> ):boolean{
		/**
		*
		* Check if the tabs structure (got from the json response) exists
		*/
		if ( tabs != null ) {

			var tabs_content = "";
			var tabs_links = "<ul>";

				/**
				*
				* Recommendations box
				*/
				tabs_content += "<div id='recommendations_box'></div>";

				/**
				*
				* Filters box
				*/
				tabs_content += "<div id='filters_box'></div>";

				/**
				*
				* All tabs
				*/
				for (var i = 0; i < tabs.length; i++) {
					var tab = Json.getSubobject( tabs[i], "tab" );
					var type = Json.getSubobject( tabs[i], "type" );
					var content = Json.getSubobject( tabs[i], "content" );

					if ( tab != "all" ) {
						tabs_links += "<li "+ Globals.ATTRIBUTE_PREFIX +"tab='" + tab + "' " + Globals.ATTRIBUTE_PREFIX +"type='" + type + "'><a href='#tab-" + tab + "'>"+ content +" (<span "+ Globals.ATTRIBUTE_PREFIX +"tab-hits='"+ tab +"'></span>)</a></li>";

						tabs_content += "<div id='tab-" + tab + "'>";
						tabs_content += "</div>";
					}
				}

				/**
				*
				* Next page button
				*/
				tabs_content += this.getModule().getComponent( "next_page_button", false );

			tabs_links += "</ul>";

			this.tabs.append( tabs_links + tabs_content );

			this.recommendationsBox = jQuery( "#recommendations_box" );
			this.filtersBox = jQuery( "#filters_box" );
			this.nextPageButton = jQuery( "#next_button" );

			this.tabsBuilded = true;

			return true;

		} else {
			return false;
		}
	}

	/**
	*
	* Build recommendations
	*/
	private buildCurrentRecommendations ( recommendations:Array<Object> ):void{
		if ( this.recommendationsBox.length && recommendations.length > 0 ) {
			
			var output:string = "";

			for (var i = 0; i < recommendations.length; i++) {
				var link = Json.getSubobject( recommendations[i], "link" );
				var pictureurl = Json.getSubobject( recommendations[i], "pictureurl" );
				/**
				*
				* Create the html output for all recommendations
				*/
				var modelID = this.getModel().new();
				if ( pictureurl != "" ) {
					this.getModel().add( modelID, "picture", Json.getSubobject( recommendations[i], "pictureurl" ) );
					this.getModel().add( modelID, "link", link );

					output += this.process( modelID, "recommendations_row_content_picture");
				} else {
					this.getModel().add( modelID, "title", Json.getSubobject( recommendations[i], "title" ) );
					this.getModel().add( modelID, "description", Json.getSubobject( recommendations[i], "description" ) );
					this.getModel().add( modelID, "link", link );
					
					output += this.process( modelID, "recommendations_row_content_text" );
				}
			}
			var modelID = this.getModel().new();
			this.getModel().add( modelID, "content", output );
			
			this.recommendationsBox.html( this.process( modelID, "recommendations_row_container" ) );	
		}
	}

	/**
	*
	* Destroy recommendations
	*/
	private destroyRecommendations ():void{
		if ( this.recommendationsBox.length ) {
			this.recommendationsBox.html("");
		}
	}

	/**
	*
	* Build orderby
	*/
	private buildOrderby ():void{
		if ( this.orderbyBox.length ) {
			
			/**
			*
			* Build the orderby structure for the current tab
			*/
			if ( this.tab == "shop" ) {

				var content = "";
				for (var i = 0; i < ControllerSearch.ODER_BY_ITEMS.length; i++) {
					var modelID = this.getModel().new();
					
					this.getModel().add( modelID, "id", ControllerSearch.ODER_BY_ITEMS[ i ].getID() );
					this.getModel().add( modelID, "selected", this.currentOrderByItem == ControllerSearch.ODER_BY_ITEMS[ i ].getID() ? "selected" : "" );
					this.getModel().add( modelID, "label", ControllerSearch.ODER_BY_ITEMS[ i ].getLabel() );

					content += this.process( modelID, "orderby_row" )
				}

				var modelID = this.getModel().new();
				this.getModel().add( modelID, "content", content );
				
				this.orderbyBox.html( this.process( modelID, "orderby_container" ) );	
			} else {
				this.destroyOrderby();
			}
		}
	}

	/**
	*
	* Destroy recommendations
	*/
	private destroyOrderby ():void{
		if ( this.orderbyBox.length ) {
			this.orderbyBox.html("");
		}
	}

	/**
	*
	* Build Filters
	*/
	private buildCurrentTabFilters ( filters:Array<Object> ):void{
		if ( this.filtersBox.length ) {

			this.filtersBox.html( "" );

			if ( filters != null ) {
					/**
					*
					* Process all filters
					*/
					var output = "";

					for (var i = 0; i < filters.length; i++) {

						var name = Json.getSubobject( filters[i], "name" );
						var value = Json.getSubobject( filters[i], "value" );

						/**
						 * 
						 * Check if the filter is disabled
						 */
						if ( ControllerSearch.DISABLED_FILTERS.indexOf( name ) == -1 ){
							
							var current_filter:Filter;
							var current_filter_content:string = null;
							
							for (let j = 0; j < ControllerSearch.FILTERS.length; j++) {
								if ( ControllerSearch.FILTERS[j].getName() == name ){
									current_filter = ControllerSearch.FILTERS[j];
									current_filter_content = ControllerSearch.FILTERS[j].create( value );
	
									ControllerSearch.FILTERS[j].activate();
	
									break;
								}
							}
	
							/**
							*
							* Check if the filters content isn´t emtpy
							* and create the container for the filters
							*/
							if ( current_filter_content != null ) {
								var modelID = this.getModel().new();
	
								this.getModel().add( modelID, "icon", current_filter.getIcon() );
								this.getModel().add( modelID, "name", "filter_toggle_" + current_filter.getName() );
								this.getModel().add( modelID, "label", current_filter.getHeadline() );
								
								this.getModel().add( modelID, "content", current_filter_content );
	
								output += this.process( modelID, "filter_container" );
							}

						}
					}

				this.filtersBox.html( output );
				this.filtersLoaded = false;

				this.currentTabFiltersAssignEvents();
			}
		}

	}

	/**
	*
	* Destroy filters
	*/
	private destroyCurrentTabFilters ():void{
		if ( this.filtersBox.length ) {
			this.filtersBox.html( "" );
		}
	}

	/**
	*
	* Bind filter events
	*/
	private currentTabFiltersAssignEvents ():void{
		for (let i = 0; i < ControllerSearch.FILTERS.length; i++) {
			if ( ControllerSearch.FILTERS[i].isActive() ){
				ControllerSearch.FILTERS[i].assignEvents();
			}
		}
	}

	/**
	*
	* Process current tab content
	*/
	private refreshCurrentTabContent ( results:Array<Object>, doyoumean:Array<Object> ):void{		
		var current_tab_content_element = jQuery( "#tab-" + this.tab + " .item_listing" );
		if ( current_tab_content_element.length ) {

			var doyoumean_output = "";
			if ( doyoumean != null && doyoumean.length > 0 ) {

				doyoumean_output += "<span class='doyoumean_headline'>"+ this.getModule().getLabel( "doyoumean_headline" ) +"</span>&nbsp;";
				for ( var i = 0; i < 2; i++ ) {

					if ( doyoumean.length >= i && doyoumean[i] !== this.query ) {

						doyoumean_output += "<a class='doyoumean_value' "+ Globals.ATTRIBUTE_PREFIX +"tag='"+ doyoumean[i] +"'><i>"+ doyoumean[i] +"</i></a>";
						if ( i < 1 && doyoumean[i + 1] !== this.query ) {
							doyoumean_output += ",&nbsp;";
						}

					}

				}

				current_tab_content_element.append(this.processOne( "doyoumean_container", "content", doyoumean_output )  );
			}

			var items_output = "";
			if ( results != null ) {
				switch ( this.tab ){
					case "shop":
						var items_tmp = this.processShop( results );
						if ( items_tmp != null ) {
							items_output = items_tmp;
						}
					break;
					case "information":
						var items_tmp = this.processInformation( results );
						if ( items_tmp != null ) {
							items_output = items_tmp;
						}
					break;
				}

				current_tab_content_element.append( items_output );

				if ( this.tab == "shop" && this.tabShopDummyRows.length > 0 ){
					for (let i = 0; i < this.tabShopDummyRows.length; i++) {
						this.tabShopDummyRows[i].remove();
						current_tab_content_element.append( this.tabShopDummyRows );
					}
				}
			}

		}
	}

	/**
	*
	* Build current tab structure
	*/
	private buildCurrentTabStructure ():void{
		var current_tab_element = jQuery( "#tab-" + this.tab );
		if ( current_tab_element.length ) {

			var result = "";
			var modelID = this.getModel().new();

			switch ( this.tab ){
				case "shop":
					this.getModel().add( modelID, "hits", this.hits );
					this.getModel().add( modelID, "query", this.query );

					result += this.process( modelID, "tab_shop_structure" );
				break;
				case "information":
					result += this.process( modelID, "tab_information_structure" );
				break;
			}

			current_tab_element.html( result );

			this.orderbyBox = jQuery( "#orderby" );

		}
	}

	/**
	*
	* Process shop tab content
	*/
	private processShop ( results:Array<Object> ):string{
		if ( results != null ) {

			var html_content = "";

				/**
				*
				* Build structure for all products
				*/
				var link_article_details = this.getModule().getLabel( "link_article_details" );

				if ( ControllerSearch.ARTICLE_SYNC ) {
					results = this.articleUpdate( results );
				}

				for (var i = 0; i < results.length; i++) {
					var modelID = this.getModel().new();

					this.getModel().add( modelID, "url", Json.getSubobject( results[i], "url" ) );
					this.getModel().add( modelID, "title", Json.getSubobject( results[i], "title" ) );
					this.getModel().add( modelID, "image", Json.getSubobject( results[i], "picture" ) );
					this.getModel().add( modelID, "brand", Json.getSubobject( results[i], "brand" ) );
					this.getModel().add( modelID, "availability", Json.getSubobject( results[i], "availability" ) );
					this.getModel().add( modelID, "price", Json.getSubobject( results[i], "price" ) );
					this.getModel().add( modelID, "details_link", link_article_details );
					this.getModel().add( modelID, "amount", Json.getSubobject( results[i], "amount" ) );
					this.getModel().add( modelID, "unit", Json.getSubobject( results[i], "unit" ) );
					this.getModel().add( modelID, "article_number", Json.getSubobject( results[i], "articlenumber" ) );
					this.getModel().add( modelID, "info", Json.getSubobject( results[i], "info" ) );

					if ( Json.getSubobject( results[i], "old" ) != null ){
						this.getModel().add( modelID, "old", Json.getSubobject( results[i], "old" ) );
					}

					html_content += this.process( modelID, "tab_shop_row" );
				}
			return html_content;
		} else {
			return null;
		}
	}

	private articleUpdate ( results:Array<Object> ):Array<Object>{
		var article_numbers = [];
		
		for (var i = 0; i < results.length; i++) {
			article_numbers.push( Json.getSubobject( results[i], "articlenumber" ) );
		}

		var article_sync_data = Json.getSubobject( this.getModel().getArticleSyncData( article_numbers ), "arts" );
		if ( article_sync_data != null ) {

			for (var i = 0; i < article_sync_data.length; i++) {
				for (var j = 0; j <	results.length; j++) {

					/**
					 * 
					 * Check if the current article number matches with the article_sync article number
					 */
					if ( Json.getSubobject( article_sync_data[i], "artnr" ) == Json.getSubobject( results[j], "articlenumber" ) ) {

						/**
						 * 
						 * Loop through all keys to replace the old value with the new 
						 */
						for (let k = 0; k < ControllerSearch.ARTICLE_SYNC_ITEMS.length; k++) {
							/**
							 * 
							 * Save the current value to use in in the articel list: old.[key]
							 */
							Json.setSubobject( results[j], "old." + ControllerSearch.ARTICLE_SYNC_ITEMS[k].getKey(), Json.getSubobject( results[j], ControllerSearch.ARTICLE_SYNC_ITEMS[k].getKey() ) );
							
							/**
							 * 
							 * Replace the current value with the new value
							 */
							Json.setSubobject( results[j], ControllerSearch.ARTICLE_SYNC_ITEMS[k].getKey(), Json.getSubobject( article_sync_data[i], ControllerSearch.ARTICLE_SYNC_ITEMS[k].getValue() ) );
						}
					}

				}
			}

		}

		return results;
	}

	/**
	*
	* Process information tab content
	*/
	private processInformation ( results:Array<Object> ):string{
		if ( results != null ) {

			var html_content:string = "";

				/**
				*
				* Build structure for all informations
				*/
				for (var i = 0; i < results.length; i++) {
					var current_row = "";
					
					/**
					*
					* Build description
					*/
					let description = Json.getSubobject( results[i], "description" );
					let description_out = "";

					if ( ControllerSearch.TAB_INFORMATION_DISPLAY_DESCRIPTION ) {
						if ( description !== "" ) {
							
							description_out += this.processOne( "tab_information_content_container_description", "description", description );
						}
					}

					/**
					*
					* Build keywords
					*/
					var keywords_out = "";
					if ( ControllerSearch.TAB_INFORMATION_DISPLAY_KEYWORDS ) {
						
						var keywords = Json.getSubobject( results[i], "keywords.keyword" );
						keywords = Json.convertObjectToArray( keywords );
							
						for (var j = 0; j < 6; j++) {
							if ( keywords.length -1 >= j && keywords[j] != "" ) {
								var modelID = this.getModel().new();

								this.getModel().add( modelID, "icon", findIcon( "keyword" ) );
								this.getModel().add( modelID, "keyword", keywords[j] );

								keywords_out += this.process( modelID, "tab_information_content_row_keywords" );
							}
						}

						if ( keywords_out != "" ) {
							keywords_out = this.processOne( "tab_information_content_container_keywords", "content", keywords_out );
						}
						
					}

					/**
					*
					* Check if both keywords and descriptions are set
					*/
					if ( keywords_out == "" && description_out == "" ) {
						this.processOne( "tab_information_content_container_content", "content", Json.getSubobject( results[i], "content") );
					} else {
						current_row = description_out + keywords_out;
					}

					var modelID = this.getModel().new();

					this.getModel().add( modelID, "url", Json.getSubobject( results[i], "url" ) );
					this.getModel().add( modelID, "title", Json.getSubobject( results[i], "title" ) );
					this.getModel().add( modelID, "rating", Json.getSubobject( results[i], "score" ));
					this.getModel().add( modelID, "content", current_row );

					html_content += this.process( modelID, "tab_information_content_structure" );
				}

			return html_content;
		} else {
			return null;
		}
	}

	/**
	*
	* Show or hide next page button
	*/
	private buildCurrentTabAutoReload ():void{
		if ( this.nextPageButton.length ) {
			
			if ( this.page && this.pages && this.page + 1 > this.pages ) {
				this.nextPageButton.hide();
			} else {
				this.nextPageButton.show();
			}

		}
	}

	/**
	*
	* Set the current tab hits
	*/
	private setCurrentTabHits ():void{
		if ( this.hits !== null ) {
			var current_tab_hits_element = jQuery( "["+ Globals.ATTRIBUTE_PREFIX +"tab-hits='" + this.tab + "']" );
			if ( current_tab_hits_element.length ) {

				current_tab_hits_element.html( this.hits.toString() );

			}
		}
	}

	/**
	*
	* Set tabs hits
	*/
	private setTabsHits ( tabs:Array<Object> ):void{
		if ( this.tabs != null ) {
			var tabs_hits_elements = jQuery( "["+ Globals.ATTRIBUTE_PREFIX +"tab-hits]" );
			for (var i = 0; i < tabs_hits_elements.length; i++) {

				for (var j = 0; j < tabs.length; j++) {

					var tab = Json.getSubobject( tabs[j], "tab" );
					var hits = Json.getSubobject( tabs[j], "hits" );

					if ( tab == jQuery( tabs_hits_elements[i] ).attr( ""+ Globals.ATTRIBUTE_PREFIX +"tab-hits" ) ) {
						jQuery( tabs_hits_elements[i] ).html( hits );
					}

				}
			}
		}
	}

	/**
	*
	* Assign the static events
	*/
	private assignStaticEvents ():void{

		
		/**
		*
		* Init tab container as jQuery UI tabs
		* Set on tab change event
		*/
		this.tabs.tabs({
			beforeActivate: function( event:Event, ui:JQueryUI.TabsActivationUIParams ) { // On change event
				var new_tab_tab = ui.newTab.attr( Globals.ATTRIBUTE_PREFIX + "tab" );
				var new_tab_type = ui.newTab.attr( Globals.ATTRIBUTE_PREFIX + "type" );

				if ( typeof new_tab_tab !== "undefined" && typeof new_tab_type !== "undefined" ) {
					this.tab = new_tab_tab;
					this.type = new_tab_type;

					this.onTabChange();
				}
			}.bind(this)
		});

		
		if ( ControllerSearch.AUTO_SCROLL ) {
			/**
			*
			* Display the new page if the user scrolled to the of the page
			*/
			jQuery( window ).scroll( function() {
				if ( ControllerSearch.AUTO_SCROLL_FIRST_CLICK && this.activatedAutoScrolling ){

				}
				var last_item = jQuery( ".item_listing *" ).last();
				if ( last_item.length ) {

					var page_end = jQuery( window ).height();
					var last_item_pos_relative_to_window = last_item.offset().top - jQuery( window ).scrollTop();
				
					/**
					*
					* Fires if the distance between the last_item_pos and the page_height is highter than 400px
					*/
					if ( last_item_pos_relative_to_window + ControllerSearch.SCROLL_DISTANCE_TO_BOTTOM <= page_end ) {
						if ( this.page + 1 <= this.pages ) {
							this.page++;
							this.refresh_content();
						}
					}

				}
			}.bind(this));
		}

		/**
		*
		* Next page button click event
		*/
		if ( this.nextPageButton.length ) {

			this.nextPageButton.on( "click", function (){
				if ( this.page + 1 <= this.pages ) {
					this.page++;
					this.refresh_content();
				}
			}.bind(this));

		}

		this.assignTabChangeEvents();
		this.assignContentChangeEvents();

		this.assignedEvents = true;
	}

	/**
	*
	* Assign the dynamic events
	*/
	private assignContentChangeEvents ():void{
		/**
		*
		* Search by tag
		*/
		var tags = jQuery( "["+ Globals.ATTRIBUTE_PREFIX +"tag]" );
		for (var i = 0; i < tags.length; i++) {
			tags[i].addEventListener( "click", function( event:any ){
				this.query = jQuery( event.currentTarget ).attr( ""+ Globals.ATTRIBUTE_PREFIX +"tag" );
				
				this.refreshAll();
				this.onContentChange();

				modules.callMethod( "Autocomplete", "set_keyword", [ this.query ] );
				jQuery( "body, html" ).animate( { scrollTop: 0 }, 1000 );
			}.bind(this));
		}

		/**
		*
		* Change the products order
		*/
		jQuery( "#orderby_select" ).on( "change", function ( e:React.FormEvent<HTMLSelectElement> ){
			this.currentOrderByItem = e.currentTarget.options[ e.currentTarget.selectedIndex ].getAttribute( ""+ Globals.ATTRIBUTE_PREFIX +"orderby" );
			this.refreshAll();
			this.onContentChange();
		}.bind(this));
	}

	/**
	*
	* Assign the dynamic events
	*/
	private assignTabChangeEvents ():void{
			
	}

	public onContentChange ():void{
		this.assignContentChangeEvents();

		this.getModule().executeEvent( "content_change" );
	}
	/**
	*
	* Change the tab
	*/
	public onTabChange ():void{
		this.filtersLoaded = true;

		this.destroyCurrentTabFilters();
		
		this.refreshAll();

		this.assignTabChangeEvents();
		this.onContentChange();

		this.getModule().executeEvent( "tab_change" );
		Collapse.init();
	}

	public getCaspardoQuery ():string{
		return ControllerSearch.CASPARDO_QUERY;
	}

	public getCaspardoBrand ():string{
		return ControllerSearch.CASPARDO_BRAND;
	}

}