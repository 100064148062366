import { Controller } from "./../../classes/mvc/Controller";
import { Globals } from "./../../classes/Globals";
import { ModelAutocomplete } from "./ModelAutocomplete";
import { Times } from "./../../libs/Times";
import { Elements } from "./../../libs/Elements";
import { Json } from "./../../libs/Json";
import { UserAgents } from "./../../libs/UserAgents";
import { modules } from "../../main";
import { KeyCodes } from "./../../classes/Globals";
import jQuery = require( "jquery" );
import { ClickEvent } from "../../classes/ClickEvent";

declare var ezentrum_variables:any;

export class ControllerAutocomplete extends Controller<ModelAutocomplete>{

    public constructor ( accessName:string, accessID:number, inputField:JQuery<HTMLElement>, outputContainer:JQuery<HTMLElement>, submitButton:JQuery<HTMLElement> ){
		super( new ModelAutocomplete(), accessName, accessID );
		
		this.inputField = inputField;
		this.outputContainer = outputContainer;
		this.submitButton = submitButton;
	}

	private static SEARCH_URL:string = "";
    private static TRACKING_URL:string = "";

    private static TARGET_URL_QUERY:string = "";
	private static TARGET_URL_BRAND:string = "";

	private static HEADLINE_RECOMMENDATIONS:string = "";
	private static HEADLINE_BRANDS:string = "";
	private static HEADLINE_CONTENTS:string = "";
	private static HEADLINE_KEYWORDS:string = "";
	private static HEADLINE_PRODUCTS:string = "";

	private static INPUT_PLACEHOLDER:string = "";

	private static SEARCH_ENGINE_ID:number = -1;

	private static SEARCH_BY_PRODUCTS:boolean = false;
    private static SEARCH_BY_KEYWORDS:boolean = false;
    private static SEARCH_BY_CONTENTS:boolean = false;
    private static SEARCH_BY_BRANDS:boolean = false;
    private static SEARCH_BY_RECOMMENDATIONS:boolean = false;

	private CASPARDO_QUERY:string;

    private timer:any;
	private interval:number;
	
    private currentSelection:number;
    private currentURL:string;
    private currentKeyword:string;
	private currentTrackingType:string;

	private inputField:JQuery<HTMLElement>;
	private outputContainer:JQuery<HTMLElement>;
	private submitButton:JQuery<HTMLElement>;

	public initGlobals ():void{
		var targetUrl = this.getModule().getConfig( "target_url" ) + "&sKontaktID="+ modules.getKontaktID() +"&sKontaktKEY="+ modules.getKontaktKey() +"&sTICKCOUNT="+ modules.getTickcount();
		var search_engine_id = Number( modules.getGlobalConfig( "caspardo_language_mapping." + modules.getLanguageCode() ) );
		var searchAutocomplete = this.getModule().getConfig( "search_autocomplete" );

		this.getModule().addView( "search_url", this.getModule().getConfig( "search_url" ) );
		this.getModule().addView( "tracking_url", this.getModule().getConfig( "tracking_url" ) );

		ControllerAutocomplete.SEARCH_ENGINE_ID = search_engine_id;

		ControllerAutocomplete.SEARCH_URL = this.processOne( "search_url", "id", String( ControllerAutocomplete.SEARCH_ENGINE_ID ) );
		ControllerAutocomplete.TRACKING_URL = this.processOne( "tracking_url", "id", String( ControllerAutocomplete.SEARCH_ENGINE_ID ) );

		ControllerAutocomplete.TARGET_URL_QUERY = targetUrl + "&query=";
		ControllerAutocomplete.TARGET_URL_BRAND = targetUrl + "&brand=";

		ControllerAutocomplete.SEARCH_BY_PRODUCTS = searchAutocomplete.products == true;
		ControllerAutocomplete.SEARCH_BY_KEYWORDS = searchAutocomplete.keywords == true;
		ControllerAutocomplete.SEARCH_BY_CONTENTS = searchAutocomplete.content == true;
		ControllerAutocomplete.SEARCH_BY_BRANDS = searchAutocomplete.brands == true;
		ControllerAutocomplete.SEARCH_BY_RECOMMENDATIONS = searchAutocomplete.recommendation == true;

		ControllerAutocomplete.HEADLINE_RECOMMENDATIONS = this.getModule().getLabel( "headline_recommendations" );
		ControllerAutocomplete.HEADLINE_BRANDS = this.getModule().getLabel( "headline_brands" );
		ControllerAutocomplete.HEADLINE_CONTENTS = this.getModule().getLabel( "headline_contents" );
		ControllerAutocomplete.HEADLINE_KEYWORDS = this.getModule().getLabel( "headline_keywords" );
		ControllerAutocomplete.HEADLINE_PRODUCTS = this.getModule().getLabel( "headline_products" );

		ControllerAutocomplete.INPUT_PLACEHOLDER = this.getModule().getLabel( "input_placeholder" );

		this.setViews();
	}

	private setViews ():void{
        var container = this.getModule().getComponent( "container" );
        if ( container != null ){
            this.getModule().addView( "container", container );
        }

        if ( ControllerAutocomplete.SEARCH_BY_PRODUCTS ){
            var rowProducts= this.getModule().getComponent( "row_products" );
            if ( rowProducts != null ){
                this.getModule().addView( "row_products", rowProducts );
            }
        }

        if ( ControllerAutocomplete.SEARCH_BY_BRANDS ){
            var rowBrands= this.getModule().getComponent( "row_brands" );
            if ( rowBrands != null ){
                this.getModule().addView( "row_brands", rowBrands );
            }
        }

        if ( ControllerAutocomplete.SEARCH_BY_CONTENTS ){
            var rowContents= this.getModule().getComponent( "row_contents" );
            if ( rowContents != null ){
                this.getModule().addView( "row_contents", rowContents );
            }
        }

        if ( ControllerAutocomplete.SEARCH_BY_KEYWORDS ){
            var rowKeywords= this.getModule().getComponent( "row_keywords" );
            if ( rowKeywords != null ){
                this.getModule().addView( "row_keywords", rowKeywords );
            }
        }

        if ( ControllerAutocomplete.SEARCH_BY_RECOMMENDATIONS ){
            var rowRecommendations= this.getModule().getComponent( "row_recommendations", false );
            if ( rowRecommendations != null ){
                this.getModule().addView( "row_recommendations", rowRecommendations );
            }
        }
    }

    public run ():void{
		this.timer = 0;
		this.interval = 500;
		
		this.currentSelection;
		this.currentURL = "";
		this.currentKeyword = "";
		this.currentTrackingType = "keyword";

		/**
		*
		* Check if the elements exists
		*/
		if ( this.inputField.length && this.outputContainer.length ) {
			if ( ControllerAutocomplete.SEARCH_URL!= null ){

				this.inputField.attr( "placeholder", ControllerAutocomplete.INPUT_PLACEHOLDER );
				this.assign_static_events();

				this.setKeyword( "" );
				this.outputContainer.attr( Globals.ATTRIBUTE_PREFIX + "open", "false" );

				/**
				*
				* Check if the query exists
				*/
				this.CASPARDO_QUERY = ezentrum_variables.T_caspardo_query;
				if ( typeof this.CASPARDO_QUERY !== "undefined" && this.CASPARDO_QUERY != "" ) {
					this.setKeyword( this.CASPARDO_QUERY );
				}

			} else{
				this.getModule().error( Globals.MODULE_LOADING_ERROR + " keine Search URL angegeben wurde");
			}
		} else {
			this.getModule().error( Globals.MODULE_LOADING_ERROR + " die HTML Elemente mit den folgenden IDs nicht gefunden wurde: search_input und search_output");
		}
    }

	/**
	*
	* Set the current query
	*/
	public setKeyword  ( keyword:string ):void{
		this.currentKeyword = keyword;

		this.inputField.val( this.currentKeyword );
		this.currentURL = ControllerAutocomplete.TARGET_URL_QUERY + encodeURIComponent ( this.currentKeyword );
		
		this.start( false );
	}

	/**
	*
	* Get the current query
	*/
	private get_keyword ():string{
		return this.currentKeyword;
	}

	private assign_content_change_events  ():void{
		var items = this.outputContainer.find( "li["+ Globals.ATTRIBUTE_PREFIX + "search-item-type] a" );
			items.each( function ( i:number ){
				jQuery( items[ i ] ).on( "click", function ( event:any ){

					this.currentTrackingType = jQuery( event.target.closest("["+ Globals.ATTRIBUTE_PREFIX + "search-item-type]") ).attr( Globals.ATTRIBUTE_PREFIX + "search-item-type" ) || null;
					this.onExecute();

				}.bind(this));
			}.bind(this));
	}
	private assign_static_events ():void{
		/**
		 * 
		 * Input events
		 */
		this.inputField.on( "input", this.start.bind( this, true ) );

		/**
		 * 
		 * Key events
		 */
		this.inputField.on( "keydown", this.keyEvents.bind(this) );
		this.outputContainer.on( "keydown", this.keyEvents.bind(this) );

		/**
		 * 
		 * Execute search events
		 */
		if (this.submitButton.length){
			this.submitButton.on( "click", this.executeSearch.bind(this) );
		}

		/**
		 * 
		 * Open and close the content area
		 */
		jQuery( document ).click( function( event:Event ) {
			var clicked_on_output_container = jQuery( event.target ).parents("#search_output").length > 0 || jQuery( event.target ) == this.outputContainer;
			var clicked_on_input = event.target === this.inputField[0];
			
			if ( !clicked_on_output_container && !clicked_on_input ) {
				Elements.hideElement( this.outputContainer );
			}
		}.bind(this));

		this.inputField.on("focusin", function (){
			if ( this.outputContainer.html() != "" ) {
				Elements.showElement( this.outputContainer );
			}
		}.bind(this));
	}

	private keyEvents( event:KeyboardEvent ):void {
		switch( event.which ) {
			case KeyCodes.UP:
				this.selection_previous_item();
				event.preventDefault();
			break;

			case KeyCodes.DOWN:
				this.selection_next_item();
				event.preventDefault();
			break;

			case KeyCodes.ENTER:
				this.executeSearch();
				event.preventDefault();
			break;
		}
	}

	private executeSearch ():void{
		if ( this.currentURL != "" ) {
			this.onExecute();

			window.location.href = this.currentURL;
		}
	}

	private start ( auto_display:boolean ):void{
		this.currentKeyword = this.inputField.val() as string;
		this.currentURL = ControllerAutocomplete.TARGET_URL_QUERY + encodeURIComponent ( this.currentKeyword );

		clearTimeout( this.timer );

		this.timer = setTimeout( function(){
			/**
			*
			* Get the search results
			*/

			var search_results:Object = this.getModel().getSearchResults( ControllerAutocomplete.SEARCH_URL+ "&query=" + encodeURIComponent( this.currentKeyword ) + "&output=json&language=" + modules.getLanguageCode() );
			if ( search_results != null ) {

				/**
				*
				* Process the search results and output them
				*/
				this.printSearchResults( this.process_search_results( search_results ), auto_display );
				this.onContentChange();
				ClickEvent.init();
			}

		}.bind(this), this.interval);
	}

	/**************************************** PRINT ****************************************/

	private printSearchResults  ( html_output:string, auto_display:boolean ):void{
	
		if ( html_output != "" ) {
			this.outputContainer.html( html_output );
		} else {
			this.outputContainer.html( "" );
		}

		if ( auto_display ) {

			if ( this.outputContainer.html() != "" ) {
				this.outputContainer.attr( Globals.ATTRIBUTE_PREFIX + "open", "true" );
			} else {
				this.outputContainer.attr( Globals.ATTRIBUTE_PREFIX + "open", "false" );
			}

		}

		this.currentSelection = -1;
	}

	/**************************************** PROCESS RESULTS ****************************************/

	private process_search_results  ( search_results:Object ):string{
		if ( typeof search_results !== undefined ) {

			var html_output = "";

			if ( ControllerAutocomplete.SEARCH_BY_KEYWORDS ) {
				var items = this.processKeywords( Json.getSubobject( search_results, "autocomplete.keywords.keyworditem" ) );
				html_output += ( items != null ? items : "" );
			}

			if ( ControllerAutocomplete.SEARCH_BY_PRODUCTS ) {
				var items = this.processProducts( Json.getSubobject( search_results, "autocomplete.products.productnameitem" ) );
				html_output += ( items != null ? items : "" );
			}

			if ( ControllerAutocomplete.SEARCH_BY_BRANDS ) {
				var items = this.processBrands( Json.getSubobject( search_results, "autocomplete.brand.branditem" ) );
				html_output += ( items != null ? items : "" );
			}

			if ( ControllerAutocomplete.SEARCH_BY_RECOMMENDATIONS ) {
				var items = this.processRecommendations( Json.getSubobject( search_results, "autocomplete.recommendation.entry" ) );
				html_output += ( items != null ? items : "" );
			}

			if ( ControllerAutocomplete.SEARCH_BY_CONTENTS ) {
				var items = this.processContents( Json.getSubobject( search_results, "autocomplete.contentall.item" ) );
				html_output += ( items != null ? items : "" );
			}
			
			return html_output;
		} else {
			return null;
		}
	}
	private processRecommendations ( recommendations:any ):string{
		if ( recommendations != null ) {
			recommendations = Json.convertObjectToArray( recommendations );
            
            var output = "";

			if ( recommendations.length > 0 ) {

				for (var i = 0; i < recommendations.length; i++) {
					var modelID = this.getModel().new();

                    this.getModel().add( modelID, "link", recommendations[i].link );
                    this.getModel().add( modelID, "title", recommendations[i].title );
					
					output += this.process( modelID, "row_recommendations" );
                }
				
				return this.processRow( output, ControllerAutocomplete.HEADLINE_RECOMMENDATIONS );
			}
		} else {
			return null;
		}
	}
	private processBrands ( brands:any ):string{
		if ( brands != null ) {
			brands = Json.convertObjectToArray( brands );

			var output = "";
			
			if ( brands.length > 0 ) {
				for (var i = 0; i < brands.length; i++) {
					var modelID = this.getModel().new();

                    this.getModel().add( modelID, "link", ControllerAutocomplete.TARGET_URL_BRAND + encodeURIComponent( brands[i].brandname ) );
                    this.getModel().add( modelID, "name", brands[i].brandname );
					
					output += this.process( modelID, "row_brands" );
                }
				
				return this.processRow( output, ControllerAutocomplete.HEADLINE_BRANDS );
			}

		} else {
			return null;
		}
	}
	private processContents ( contents:any ):string{
		if ( contents != null ) {
			contents = Json.convertObjectToArray( contents );

			var output = "";

			if ( contents.length > 0 ) {
				for (var i = 0; i < contents.length; i++) {
					var modelID = this.getModel().new();

                    this.getModel().add( modelID, "link", contents[i].url );
                    this.getModel().add( modelID, "title", contents[i].title );
					
					output += this.process( modelID, "row_contents" );
                }
				
				return this.processRow( output, ControllerAutocomplete.HEADLINE_CONTENTS );
			}

		} else {
			return null;
		}
	}
	private processKeywords ( keywords:any ):string{
		if ( keywords != null ) {
			keywords = Json.convertObjectToArray( keywords );

			var output = "";

			if (keywords.length > 0 ) {
				for (var i = 0; i < keywords.length; i++) {
					var modelID = this.getModel().new();

                    this.getModel().add( modelID, "link", ControllerAutocomplete.TARGET_URL_QUERY +  encodeURIComponent( keywords[i].queryword ) );
                    this.getModel().add( modelID, "queryword", keywords[i].queryword );
					
					output += this.process( modelID, "row_keywords" );
				}

				return this.processRow( output, ControllerAutocomplete.HEADLINE_KEYWORDS );
			}

		} else {
			return null;
		}
	}

	private processProducts ( products:any ):string{
		if ( products != null ) {
				products = Json.convertObjectToArray( products );

				var output = "";

			if ( products.length > 0 ) {
				for (var i = 0; i < products.length; i++) {
					var modelID = this.getModel().new();

                    this.getModel().add( modelID, "link", products[i].productlink );
                    this.getModel().add( modelID, "articlenumber", products[i].productarticlenumber );
                    this.getModel().add( modelID, "name", products[i].productname );
                    this.getModel().add( modelID, "picture", products[i].productthumbnail );
					
					output += this.process( modelID, "row_products" );
                }
				
				return this.processRow( output, ControllerAutocomplete.HEADLINE_PRODUCTS );
			}
			
		} else {
			return null;
		}
	}

	private processRow ( output:string, headline:string ):string{
		var modelID = this.getModel().new();

		this.getModel().add( modelID, "headline", headline );
		this.getModel().add( modelID, "content", output );

		return this.process( modelID, "container" );
	}

	/**************************************** PROCESS SELECTION ****************************************/

	private selection_previous_item  ():void{
		this.selectionChangeItem( -1 );
	}

	private selection_next_item  ():void{
		this.selectionChangeItem( 1 );
	}

	private selectionChangeItem  ( index:number ):void{
		var all_items = this.outputContainer.find( ".search_item" );
		
		var current_selection = this.currentSelection;
		var next_selection = this.currentSelection + index;
		
		if ( next_selection >= 0 && next_selection < all_items.length ) {
			
			var current_item = all_items[ current_selection ] || null;
			var next_item = all_items[ next_selection ];

			var type = next_item.getAttribute( Globals.ATTRIBUTE_PREFIX + "search-item-type" );
			var current_url = next_item.getAttribute( Globals.ATTRIBUTE_PREFIX + "search-item-link" );
			var current_name = next_item.getAttribute( Globals.ATTRIBUTE_PREFIX + "search-item-name" );

			this.currentURL = current_url;
			this.currentTrackingType = type;

			if ( type == "keyword" ) {
				this.inputField.val( current_name );
			}

			if ( current_item != null ) {
				current_item.setAttribute( Globals.ATTRIBUTE_PREFIX + "search-item-active", "false" );
			}
			next_item.setAttribute( Globals.ATTRIBUTE_PREFIX + "search-item-active", "true" );

			this.currentSelection = next_selection;

		} else if ( next_selection == -1 ) {
			
			all_items[ 0 ].setAttribute( Globals.ATTRIBUTE_PREFIX + "search-item-active", "false" );
			
			this.currentSelection = -1;
			this.inputField.val( this.currentKeyword );
			this.currentURL = ControllerAutocomplete.TARGET_URL_QUERY + encodeURIComponent( this.currentKeyword );
		}
	}

	private onContentChange  ():void{
		this.assign_content_change_events();
	}

	private onExecute  ():void{
		if ( this.currentTrackingType !== null ) {

			var url = ControllerAutocomplete.TRACKING_URL + "&area=" + this.currentTrackingType + "&device=" + UserAgents.getForTracking();

			jQuery.ajax({
				url: url,
				type: "GET",
				async: false,
				dataType: "text",
				success: function (){ modules.log( "Autocomplete Tracking: "+ Times.printTimestamp() +" Status: erfolgreich" ); },
				error: function (){ modules.error( "Autocomplete Tracking: "+ Times.printTimestamp() +" Status: nicht erfolgreich" ); }
			});

		}
	}
}